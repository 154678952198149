import { useEffect } from "react";
import { Box } from "@mui/material";
import * as React from "react";
import Favicon from "react-favicon";

export default function MetaHeader({ children }) {
  useEffect(() => {
    document.title = "Mosaic OOS Demo CMS";
  }, []);

  return <Box>{children}</Box>;
}
